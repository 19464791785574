<template>
  <div>
    <single-post v-for="(post, i) in posts" :key="i" :post="post" :flat="flat" :isGuest="isGuest"/>
  </div>
</template>

<script>
import SinglePost from '@/components/Feed/SinglePost/'
export default {
  props: {
    posts: {
      type: Array,
      default: () => []
    },
    flat: {
      type: Boolean,
      default: false
    },
    isGuest: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SinglePost
  }
}
</script>

<style scoped>

</style>
