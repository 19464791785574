<template>
  <v-hover class="mb-5" v-slot:default="{ hover }">
    <v-card :elevation="post.isMine ? 0 : hover ? 4 : 1" :class="{ 'now': post.now }" :to="{ name: 'project', params: { slug: post.name,  id: post.id } }" :ripple="false" :flat="post.isMine" :outlined="post.isMine" class="outline">
      <v-list-item :two-line="!post.jobLocation" :three-line="!!post.jobLocation">
        <v-list-item-avatar size="40" v-html="post.avatar" />
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ post.authorName }}</v-list-item-title>
          <v-list-item-subtitle class="d-flex caption text--secondary"><v-icon disabled x-small style="margin-left: -1px;" class="mr-1">mdi-clock</v-icon> {{ post.date | moment('from', 'now') }}</v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex caption" v-if="post.jobLocation"><v-icon disabled x-small style="margin-left: -1px;" class="mr-1">mdi-map-marker</v-icon>{{ post.jobLocation}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn :loading="loading" @click.prevent="toggleBookmark('delete')" fab depressed text class="align-self-center actions" v-if="bookmarked && !isGuest" color="blue">
          <v-icon>mdi-bookmark</v-icon>
        </v-btn>
        <v-btn :loading="loading" @click.prevent="toggleBookmark('add')" fab depressed text class="align-self-center actions text--secondary" v-else>
          <v-icon>mdi-bookmark-outline</v-icon>
        </v-btn>
      </v-list-item>
      <v-card-title class="py-2 text-break">{{ post.title }}</v-card-title>
      <v-card-text>
        <vue-markdown :source="post.content"></vue-markdown>
        <v-chip small label class="skills mr-3 mt-3" v-for="(skill, i) in post.skills" :key="i">#{{ skill.name }}</v-chip>
        <div class="post-meta mt-6">
          <span><v-icon small color="pink">mdi-heart</v-icon>{{ post.counts.interested }}</span>
          <span><v-icon small color="green">mdi-comment-multiple</v-icon>{{ post.counts.comments }}</span>
          <span><v-icon small color="blue">mdi-bookmark</v-icon>{{ post.counts.bookmarks }}</span>
        </div>
      </v-card-text>
      <v-divider :class="post.isMine ? 'outline': ''"></v-divider>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn text v-if="post.myBid && !isGuest" color="success" @click.prevent small>
          <v-icon color="success">mdi-check</v-icon>
          <span class="ml-2" v-if="!$vuetify.breakpoint.smAndDown">{{ post.myBid.date | moment('from', 'now') }}</span>
          <span class="ml-2" v-else-if="!$vuetify.breakpoint.smAndDown">{{ Date.now() | moment('from', 'now') }}</span>
        </v-btn>
        <v-btn text class="text--secondary" v-else @click="applyInProject">
          <v-icon>mdi-heart-outline</v-icon><span class="ml-2" v-if="!$vuetify.breakpoint.smAndDown">Interested</span>
        </v-btn>
        <v-btn text :to="{ name: 'project', params: { slug: post.name,  id: post.id } }" class="text--secondary">
          <v-icon>mdi-comment-multiple-outline</v-icon><span class="ml-2" v-if="!mdScreen">Comment</span>
        </v-btn>
        <v-menu left bottom offset-y min-width="280" dark>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="text--secondary" @click.prevent>
              <v-icon>mdi-share-outline</v-icon><span class="ml-2" v-if="!mdScreen">Share</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-icon class="mr-1">mdi-content-copy</v-icon> Copy Link
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>
                <v-icon class="mr-1">mdi-whatsapp</v-icon> Share on WhatsApp
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-icon class="mr-1">mdi-facebook</v-icon> Post to Facebook
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-icon class="mr-1">mdi-linkedin</v-icon> Post to LinkedIn
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-icon class="mr-1">mdi-twitter</v-icon> Post to Twitter
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
      <div v-if="flat">
        <v-divider></v-divider>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import breakPoints from '../../mixins/breakPoints'
import ADD_BOOKMARK from '../../graphql/mutations/addBookmark.graphql'
import DELETE_BOOKMARK from '../../graphql/mutations/deleteBookmark.graphql'
export default {
  props: {
    post: {
      type: Object,
      default: () => {}
    },
    flat: {
      type: Boolean,
      default: false
    },
    isGuest: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    bookmarked () {
      return this.post.isBookmarked
    }
  },
  methods: {
    async toggleBookmark (type) {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: type === 'add' ? ADD_BOOKMARK : DELETE_BOOKMARK,
          variables: {
            id: type === 'add' ? this.post.id : this.post.isBookmarked
          },
          update: (store, { data: { addBookmark } }) => {
            if (type === 'add') {
              this.post.isBookmarked = addBookmark
              this.post.counts.bookmarks++
            } else {
              this.post.isBookmarked = null
              this.post.counts.bookmarks--
            }
          }
        })
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    },
    // copyLink () {
    //   let copy = document.querySelector('#testing-code')
    //   testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
    //   testingCodeToCopy.select()
    //
    //   try {
    //     var successful = document.execCommand('copy');
    //     var msg = successful ? 'successful' : 'unsuccessful';
    //     alert('Testing code was copied ' + msg);
    //   } catch (err) {
    //     alert('Oops, unable to copy');
    //   }
    //
    //   /* unselect the range */
    //   testingCodeToCopy.setAttribute('type', 'hidden')
    //   window.getSelection().removeAllRanges()
    // },
    applyInProject () {
      this.$router.push({
        name: 'project',
        params: {
          slug: this.post.name, id: this.post.id
        },
        query: { apply: !this.isGuest }
      })
    }
  },
  mixins: [breakPoints]
}
</script>

<style scoped lang="scss">
  .now {
    animation-name: animationName;
    animation-duration: 1s;
  }
  .outline {
    border-color: var(--v-info-base);
  }
  @keyframes animationName {
    0%  {
      background-color: var(--v-warning-base);
    }
  }
</style>
